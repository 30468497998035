.rejected-match-container {
    border-radius: 25px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: var(--card-bg-color);
    border: 1px solid var(--border-color);
    width: 300px;
    margin: 0 0 1em 1em;
    opacity: 0.8;
    text-align: center;
    padding: 5px 0;
}

.match-information-container {
    justify-content: center;
}

.vs-text {
    font-size: 10px;
}
.match-information-span-text {
    font-size: 10px;
    color: var(--primary-text-color);
}

.player-span-text {
    font-size: 14px;
    color: var(--primary-text-color);
}

.match-board-score {
    color: rgb(121, 24, 24);
    font-size: 14px;
}

.player-score-span-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-text-color);
}

.custom-approval-reject-button {
    cursor: pointer;
}

.actions-column {
    display: flex;
    justify-content: center;
    padding: 10px;
}
