.reset-password-logo-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 5em;
}
.reset-password-form-container {
    padding-bottom: 5em;
    display: flex;
    justify-content: center;
    text-align: center;
}

.reset-password-row {
    padding: 1em;
}

.navigate-login-span {
    text-decoration: underline;
    cursor: pointer;
}
.navigation-row-container {
    padding: 2em;
}
