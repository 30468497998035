.add-match-form-container {
    border-radius: 25px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: var(--card-bg-color);
    border: 1px solid var(--border-color);
    padding: 3vh;
    margin: 0 1em;
    text-align: center;
}

.success-game-row {
    padding-top: 7vh;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    color: forestgreen;
    display: flex;
}

.failure-game-row {
    padding-top: 7vh;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    color: rgb(211, 101, 101);
    display: flex;
}

span.MuiStepLabel-label.Mui-completed {
    font-family: 'ShaliahSansBold' !important;
}
span.MuiStepLabel-label.Mui-active {
    font-family: 'ShaliahSansBold' !important;
}
span.MuiStepLabel-label.Mui-disabled {
    font-family: 'ShaliahSansBold' !important;
}

.wizard-steps-loader-container {
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wizard-steps-blur-background {
    filter: blur(2px);
    pointer-events: none;
}
