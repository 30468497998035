.results-container {
    padding: 10px;
}

.matches-results-container {
    min-height: 400px;
}

.no-result-container {
    padding-top: 5em;
}
.no-result-span-text {
    color: var(--primary-text-color);
    font-size: 14px;
}
