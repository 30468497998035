.match-header-container {
    border-radius: 30px;
    background-color: var(--card-bg-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;
    border: 1px solid var(--border-color);
}

.match-players-details {
    display: flex;
    width: 100%;
}

.match-date-details {
    padding-bottom: 2vh;
}

.img-player-round {
    border: 1px solid var(--border-color);
    border-radius: 50%;
    padding: 7px;
    height: 80px;
    width: 80px;
}

.player-container {
    width: 45%;
}

.match-score-container {
    width: 10%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.span-match-score {
    color: var(--primary-text-color);
    font-size: 20px;
}

.span-player-name-match-header {
    color: var(--primary-text-color);
    font-size: 14px;
}

.span-date {
    color: var(--primary-text-color);
    font-size: 14px;
}
