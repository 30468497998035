.search-player-container {
    align-items: center;
    padding: 5px 1em;
}

.search-player-span-title {
    font-size: 12px;
    color: var(--primary-text-color);
}

.search-player-column {
    display: flex;
    justify-content: center;
}

.search-player-container .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.tennis-matches {
    max-width: 250px;
    width: 100%;
}

.tennis-matches
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    height: 35px;
}
.tennis-matches svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: var(--primary-text-color);
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    color: var(--primary-text-color);
}

.tennis-matches fieldset.MuiOutlinedInput-notchedOutline {
    border-color: none;
}
.tennis-matches .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px;
}
.tennis-matches
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot
    input {
    color: var(--primary-text-color);
}
.tennis-matches .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root label {
    color: var(--primary-text-color);
}

.tennis-matches .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root label {
    top: -8px;
    font-size: 12px;
}

.tennis-matches
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot
    input {
    padding: 0px;
    margin-bottom: 10px;
    font-size: 12px;
}

.tennis-matches
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot
    fieldset {
    border-color: var(--primary-text-color);
}
.tennis-matches svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    color: var(--primary-text-color) !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed {
    color: var(--primary-text-color);
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active {
    color: var(--primary-text-color);
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: var(--primary-text-color);
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall {
    background-color: var(--primary-text-color);
    color: var(--card-bg-color);
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall {
    color: var(--primary-text-color);
}
