.top-navigation-container {
    position: sticky;
    top: 0;
    z-index: 9999;
    height: 7%;
}

.custom-button-tab:focus {
    outline: none;
}

.bottom-navigation-custom {
    background-color: var(--card-bg-color) !important;
    border: 1px solid var(--border-color);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    color: var(--primary-text-color) !important;
}

.css-118p9r0-MuiButtonBase-root-MuiBottomNavigationAction-root {
    color: var(--primary-text-color) !important;
}

.css-sxw2zh.Mui-selected {
    color: var(--primary-text-color) !important;
}
button.MuiButtonBase-root.MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly {
    color: var(--primary-text-color) !important;
}

.svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: var(--primary-text-color) !important;
}

span.MuiBottomNavigationAction-label.Mui-selected {
    font-family: 'ShaliahSansBold' !important;
}
