.result-box-container {
    background-color: var(--card-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 10px;
    margin: 14px 0;
    padding: 10px 0;
    cursor: pointer;
}

.match-date-row {
    justify-content: center;
}
.span-text-date {
    font-size: 10px;
}

.match-details-row {
    justify-content: center;
}
.match-details-row span {
    font-size: 14px;
}

.player-column {
    padding: 0;
}
