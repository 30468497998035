.match-set-points-container {
    border-radius: 25px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 1em 0;
    text-align: center;
    border: 1px solid var(--border-color);
    background-color: var(--card-bg-color);
}

.set-row-container {
    display: flex;
}

.set-title-span {
    margin: 0;
    color: #b3b8cd;
    font-size: 12px;
    font-weight: 500;
}

.set-column-container {
    width: 50%;
    padding-bottom: 1vh;
    display: flex;
}

.set-column-score-container {
    width: 70%;
}

.set-column-img-container {
    width: 30%;
}

.span-set-score {
    color: var(--primary-text-color);
    font-size: 14px;
}

.span-img-score {
    color: rgb(255, 255, 4);
}

.circularProgress-row {
    padding-top: 40px;
}

.custom-css-button {
    color: #b3b8cd !important;
    border: 1px var(--border-color) solid !important;
}

.reload-button-row {
    padding-top: 6vh;
}
