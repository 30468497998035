.card-container {
    border-radius: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: var(--card-bg-color);
    border: 1px solid var(--border-color);
    color: var(--primary-text-color);
    text-align: center;
    margin: 1em;
    padding: 1em;
    height: 300px;
}
.back-button-row-table {
    color: var(--primary-text-color);
    display: flex;
    justify-content: flex-end;
    text-align: left;
    align-items: center;
    padding-left: 2em;
}

.arrow-back-button {
    cursor: pointer;
}
.img-round {
    border: 1px solid var(--border-color);
    border-radius: 50%;
    padding: 7px;
    height: 130px;
}
.player-name-container {
    padding: 1em 0;
}
.edit-span {
    font-size: 12px;
    padding-right: 1em;
    text-decoration: underline;
    cursor: pointer;
}
.player-statistic-container {
    padding-right: 20px;
    text-align: right;
    font-size: 12px;
}

.player-statistic-information {
    padding-bottom: 5px;
}

.number-of-games {
    font-weight: 700;
}
