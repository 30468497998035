.forgot-password-container {
    border-radius: 30px;
    padding: 2em;
    margin: 0 2em;
    background-color: var(--card-bg-color);
    text-align: center;
    display: flex;
    justify-content: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.title-text-forgot-password {
    font-size: 14px;
}

.recaptcha-container,
.text-field-container-forgot {
    display: flex;
    justify-content: center;
    padding: 1em 0;
}
