.input-container {
    padding: 5px 0;
    width: 100%;
    max-width: 250px;
}

.input-label-container {
    justify-content: center;
    height: 22px;
}
.input-label-container > * {
    padding: 0;
}

.input-container-column {
    display: flex;
    justify-content: center;
}
@media (min-width: 576px) {
    .input-label-container {
        justify-content: start;
    }
}

.registration-check-icon {
    font-size: 12px !important;
    color: var(--primary-text-color);
    background-color: rgb(113, 207, 125);
    border-radius: 10px;
    margin-top: 6px;
}
.input-label-span {
    font-size: 12px;
    padding-bottom: 5px;
}
.mandatory-filed-input {
    font-size: 8px;
    color: red;
    padding-right: 3px;
}
.text-field-container {
    justify-content: center;
}
