.match-container {
    padding: 5vh;
}

.back-button-row {
    height: 5vh;
    color: var(--primary-text-color);
    display: flex;
    justify-content: flex-end;
    text-align: left;
    align-items: center;
}

.match-set-row {
    padding-top: 10vh;
}
