.registration-container {
    border-radius: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    margin: 0 2em;
    background-color: var(--card-bg-color);
    border: 1px solid var(--border-color);
    text-align: center;
    opacity: 0.88;
    justify-content: center;
    min-height: 394px;
}

.confirmation-row-container {
    min-height: 394px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputs-container {
    padding: 10px 0;
}

.select-input-custom {
    width: 100%;
    height: 30px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-sizeSmall {
    width: 100%;
    max-width: 250px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    height: 30px !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
    height: 30px !important;
}
.MuiFormControl-root.MuiTextField-root {
    width: 100%;
    max-width: 250px;
}
.button-cuntainer {
    justify-content: center;
    padding: 10px 0;
}

.error-message-container {
    padding: 0 10px 10px 0;
}
.span-text-error {
    font-size: 10px;
    color: red;
}
.confirmation-text {
    padding: 0 1em;
}

.MuiPickersCalendarHeader-root {
    direction: ltr !important;
}

.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blur-background {
    filter: blur(2px);
    pointer-events: none;
}
