.profile-container {
    padding: 1em 0;
    justify-content: center;
}

.player-profile-approval-game-container {
    padding-top: 1em;
    justify-content: center;
    overflow: auto;
}

.no-maches-to-show-span {
    color: var(--primary-text-color);
    font-size: 10px;
}

.guest-container {
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: var(--card-bg-color);
    border: 1px solid var(--border-color);
    padding: 3vh;
    margin: 0 1em;
    text-align: center;
}
