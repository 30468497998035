.box-modal-container {
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--card-bg-color);
    padding: 1em;
    width: 350px;
}

.approval-modal-button-row {
    align-items: center;
    padding: 2em 0;
}

.approval-modal-button-column {
    display: flex;
    justify-content: center;
}

.box-modal-header {
    padding: 1em 10px;
    border-bottom: 1px solid var(--primary-text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: 14px;
    }
}
