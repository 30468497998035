.logout-modal-container {
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--border-color);
    background-color: var(--card-bg-color);
    width: 300px;
}

.logout-modal-header {
    padding: 1em 10px;
    border-bottom: 1px solid var(--primary-text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: 14px;
    }
}
.logout-modal-context {
    padding: 2em 0;
}

.logout-modal-close-icon {
    cursor: pointer;
}

.logout-container {
    display: flex;
    justify-content: center;
}
.stay-login-container {
    display: flex;
    justify-content: center;
}

button.MuiTypography-root.MuiTypography-body2.MuiLink-root.MuiLink-underlineAlways.MuiLink-button {
    font-family: 'ShaliahSansBold' !important;
    color: var(--primary-text-color);
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeSmall.MuiButton-outlinedSizeSmall.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeSmall.MuiButton-outlinedSizeSmall {
    font-family: 'ShaliahSansBold' !important;
    color: var(--primary-text-color);
    border: 1px solid var(--border-color);
}
