.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dark-bg-color);
    color: var(--card-bg-color);
    padding: 30px;
    height: 6%;
}

.footer-container span {
    font-weight: bold;
    font-size: 14px;
}

.footer-container a {
    color: white;
}
