.edit-card-container {
    border-radius: 30px;
    background-color: var(--card-bg-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--border-color);
    text-align: center;
    margin: 1em;
    padding: 2em 1em;
    height: 300px;
}
