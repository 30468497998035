.login-form-container {
    border-radius: 30px;
    padding: 2em;
    margin: 0 2em;
    background-color: var(--card-bg-color);
    text-align: center;
    display: flex;
    justify-content: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.login-button-row {
    padding: 1em 0;
}

.password-input-row,
.username-input-row {
    justify-content: space-between;
}

.forgot-password-span {
    cursor: pointer;
}

.username-input-field {
    padding: 10px 0;
}

.error-message-row {
    text-align: right;
    padding: 1em 2em 0 0;
}

.span-text-error {
    font-size: 10px;
    color: red;
}

.MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeSmall.MuiFormHelperText-contained {
    text-align: right;
}
