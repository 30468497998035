.table-container {
    padding-left: 5px;
    padding-right: 5px;
    max-height: 500px;
}

.player-cell-custom {
    padding: 0px !important;
}

.table-player-row {
    cursor: pointer;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.player-cell-custom {
    font-family: 'ShaliahSansBold' !important;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium {
    font-family: 'ShaliahSansBold' !important;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter.MuiTableCell-sizeMedium {
    font-family: 'ShaliahSansBold' !important;
    padding: 1em 0;
}
