@font-face {
    font-family: 'ShaliahSansBold';
    src: url('../public/assets/fonts/ShaliahSansBold.ttf') format('truetype');
}

html {
    height: 100%;
}
body {
    margin: 0;
    height: 100%;
    background-color: var(--main-bg-color);
    direction: rtl;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'ShaliahSansBold', sans-serif;
}
