.auth-container {
    overflow: auto;
    text-align: center;
    padding-top: 3em;
    width: 100%;
    max-width: 800px;
}

.title-text {
    color: var(--primary-text-color);
    font-size: 28px;
    font-weight: 500;
}
.sub-title-text {
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: 500;
}

.create-new-account-text-container {
    padding: 3em 0 2em;
}

.create-account-login {
    justify-content: center;
}
.create-account-text-button {
    cursor: pointer;
    color: var(--primary-text-color);
    font-size: 14px;
}

.join-as-guest-container {
    padding: 2em 0;
    justify-content: center;
}
