.profile-tabs-container {
    padding: 10px 0;
    margin: 0 1em;
}

.tab-column {
    padding: 0;
}

.active-tab-container {
    border-bottom: 1px solid var(--border-color);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
