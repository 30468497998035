.server-error-container {
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    color: #b3b8cd;
    text-align: center;
    margin: 2em;
    padding: 3em 0;
}

.text-row {
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
}

.error-icon {
    padding-left: 1px;
    margin-left: 1px;
    padding-top: 1px;
}

.button-row {
    justify-content: center;
}

.custom-css-button {
    color: #b3b8cd !important;
    border: 1px var(--border-color) solid !important;
}
