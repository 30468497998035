.form-button-custom {
    margin: 0px !important;
}

.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    font-size: 12px !important;
}

.player-name-row {
    padding-bottom: 1vh;
}

.span-player-name {
    color: var(--primary-text-color);
    font-weight: 700;
    font-size: 16px;
}
